
import { Vue, Component } from 'vue-property-decorator';
import MasterLayout from '@/components/MasterLayout.vue';
import PostHeader from '@/components/NonLoggedInComponents/PostHeader.vue';
import PostIntro from '@/components/NonLoggedInComponents/PostIntro.vue';
import PostContact from '@/components/NonLoggedInComponents/PostContact.vue';
import axios from 'axios';
import DOMPurify from 'dompurify';
const baseUrl = `${process.env.VUE_APP_ROOT_API}/barcode`;
@Component({
  components: {
    MasterLayout,
    PostHeader,
    PostIntro,
    PostContact
  }
})
export default class BarcodeGenerator extends Vue {
  barcodeRequest = {
    BarcodeFormat: 'QR_CODE',
    BarcodeValue: '',
    Width: 300,
    Height: 300,
    ForegroundColor: '#000000',
    BackgroundColor: '#FFFFFF',
    Margin: 10,
    FileFormat: 'PNG'
  };

  barcodeData: string | null = null;
  barcodeBlob: Blob | null = null;

  get isFormValid(): boolean {
    return this.barcodeRequest.BarcodeValue.trim() !== '';
  }

  get sanitizedSvg(): string {
    if (this.barcodeData && this.barcodeRequest.FileFormat === 'SVG') {
      try {
        return DOMPurify.sanitize(this.barcodeData);
      } catch (error) {
        console.error('Error sanitizing SVG data:', error);
        return '';
      }
    }
    return '';
  }

  async generateBarcode() {
    try {
      console.log('Generating barcode with request:', this.barcodeRequest);
      const response = await axios.post(`${baseUrl}/GetBarcode`, this.barcodeRequest, {
        responseType: this.barcodeRequest.FileFormat === 'SVG' ? 'text' : 'arraybuffer'
      });

      console.log('Response received:', response);

      if (this.barcodeRequest.FileFormat === 'SVG') {
        this.barcodeData = response.data;
        console.log('SVG data received:', this.barcodeData);
        this.barcodeBlob = new Blob([this.barcodeData], { type: 'image/svg+xml' });
        this.$nextTick(() => {
          this.displaySVG();
        });
      } else {
        this.barcodeBlob = new Blob([response.data], {
          type: `image/${this.barcodeRequest.FileFormat.toLowerCase()}`
        });
        this.barcodeData = URL.createObjectURL(this.barcodeBlob);
      }
    } catch (error) {
      console.error('Error generating barcode:', error);
      // Handle error (e.g., show an error message to the user)
    }
  }

  displaySVG() {
    const container = this.$refs.svgContainer as HTMLElement;
    if (container && this.barcodeData) {
      // Parse the SVG to modify it
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(this.barcodeData, 'image/svg+xml');
      const svgElement = svgDoc.documentElement;

      // Set explicit width and height
      svgElement.setAttribute('width', `${this.barcodeRequest.Width}px`);
      svgElement.setAttribute('height', `${this.barcodeRequest.Height}px`);

      // Ensure viewBox is set correctly
      if (!svgElement.getAttribute('viewBox')) {
        svgElement.setAttribute(
          'viewBox',
          `0 0 ${this.barcodeRequest.Width} ${this.barcodeRequest.Height}`
        );
      }

      // Serialize back to string
      const serializer = new XMLSerializer();
      const modifiedSvgString = serializer.serializeToString(svgDoc);

      // Sanitize and set the modified SVG
      const sanitizedSVG = DOMPurify.sanitize(modifiedSvgString);
      container.innerHTML = sanitizedSVG;
    }
  }

  downloadBarcode() {
    if (this.barcodeData && this.barcodeBlob) {
      const link = document.createElement('a');
      if (this.barcodeRequest.FileFormat === 'SVG') {
        const blob = new Blob([this.barcodeData], { type: 'image/svg+xml' });
        link.href = URL.createObjectURL(blob);
      } else {
        link.href = this.barcodeData;
      }
      link.download = this.getFileName();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  getFileName(): string {
    const timestamp = new Date().toISOString().replace(/[:.]/g, '-');
    return `barcode-${timestamp}.${this.barcodeRequest.FileFormat.toLowerCase()}`;
  }
}
